import React from "react";
import IconWindow from "../components/iconWindow/iconWindow";

import QuestionIcon from "../assets/images/icons/question.svg"
import TribuneIcon from "../assets/images/icons/tribune.svg"
import HandshakeIcon from "../assets/images/icons/handshake.svg"
import InfoIcon from "../assets/images/icons/information-button.svg"

import VkIcon from "../assets/images/icons/vk.svg"
import TwitterIcon from "../assets/images/icons/twitter.svg"
import GithubIcon from "../assets/images/icons/github.svg"
import {graphql} from "gatsby";
import {Trans, useI18next} from "gatsby-plugin-react-i18next";
import Seo from "../components/seo/seo";

const prepareContactsIcons = (t) => [{
    id: "question",
    image: QuestionIcon,
    text: t("participation_questions"),
    href: "mailto:invite@volgactf.ru",
    hrefText: "invite@volgactf.ru"
}, {
    id: "tribune",
    image: TribuneIcon,
    text: t("speakers"),
    href: "mailto:speakers@volgactf.ru",
    hrefText: "speakers@volgactf.ru"
}, {
    id: "handshake",
    image: HandshakeIcon,
    text: t("partnership"),
    href: "mailto:partners@volgactf.ru",
    hrefText: "partners@volgactf.ru"
}, {
    id: "info",
    image: InfoIcon,
    text: t("info"),
    href: "https://vk.com/volgactf"
}
]

const prepareSocialIcons = (t) => [{
    id: "vk",
    image: VkIcon,
    href: "https://vk.com/volgactf",
    hrefText: t("vk_text"),
    linkImage: true
}, {
    id: "twitter",
    image: TwitterIcon,
    href: "https://twitter.com/volgactf",
    hrefText: "Twitter",
    linkImage: true
}, {
    id: "github",
    image: GithubIcon,
    href: "https://github.com/volgactf",
    hrefText: "Github",
    linkImage: true
}
]

const Contacts = ({location}) => {
    const {t, language} = useI18next()
    const contactsIcons = prepareContactsIcons(t)
    const socialIcons = prepareSocialIcons(t)
    return (
        <>
            <Seo title={t('contacts_title')} lang={language} description={t('how_to_contact_us')}
                 pathname={location.pathname}/>
            <section>
                <div className="grid-x">
                    <div className="large-offset-1 large-2 cell cell-text">
                        <h1>
                            <Trans>how_to_contact_us</Trans>
                        </h1>
                    </div>
                    <div className="large-6 large-offset-2 cell cell-text">
                        <IconWindow icons={contactsIcons}/>
                    </div>
                </div>
            </section>
            <section>
                <div className="grid-x">
                    <div className="large-offset-1 large-2 cell cell-text">
                        <h1>
                            <Trans>track_the_news</Trans>
                        </h1>
                    </div>
                    <div className="large-offset-2 large-6 cell cell-text">
                        <IconWindow icons={socialIcons}/>
                    </div>
                </div>
            </section>
        </>
    )
}

export const query = graphql`
    query($language: String!){
        locales: allLocale(filter: {language: {eq: $language}}){
            edges{
                node{
                    ns
                    data
                    language
                }
            }
        }
    }
`

export default Contacts
